<template>
  <div>
    <ts-page-title
      :title="$t('stockIn.pageTitle')"
      :breadcrumb="[
        { text: $t('home'), href: '/' },
        {
          text: $t('stockIn.pageTitle'),
          active: true,
        },
      ]"
    />
    <ts-panel>
      <ts-panel-wrapper>
        <div class="d-flex justify-content-between">
          <div class="tw-flex tw-items-center tw-space-x-3">
            <ts-button
              v-if="$can('create-product-profiles')"
              outline
              color="info"
              @click.prevent="addNew"
              >{{ $t("addNew") }}
            </ts-button>
            <a-range-picker
              v-model="dateRange"
              :allowClear="false"
              format="DD-MM-YYYY"
              valueFormat="DD-MM-YYYY"
              style="width: 200px"
              @change="fetchData()"
            />
          </div>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <div
              class="
                tw-border-gray-200
                tw-p-2
                tw-flex
                tw-items-center
                tw-border
                tw-rounded
              "
            >
              <input
                type="checkbox"
                v-model="isConfirmedStock"
                @change="fetchData()"
              />
              <label for="inactive" class="tw-ml-1">{{
                $t("stockIn.isConfirmedStock")
              }}</label>
            </div>
            <a-input
              v-model="search"
              v-tooltip="$t('stockIn.search')"
              :placeholder="$t('search')"
              style="width: 250px"
              class="inline-block"
            />
          </div>
        </div>
      </ts-panel-wrapper>
      <div>
        <ts-table 
          :columns="columns" 
          :records="resources" 
          :loading="loading"
          :expandable="true"
        >
          <template v-slot="{ record }">
            <td
              :class="
                record.is_deleted ? 'tw-line-through tw-text-red-600' : ''
              "
            >
              {{ record.stock_in_number }}
            </td>
            <td
              :class="
                record.is_deleted ? 'tw-line-through tw-text-red-600' : ''
              "
            >
              {{ record.shop_name }}
            </td>
            <td
              :class="
                record.is_deleted ? 'tw-line-through tw-text-red-600' : ''
              "
            >
              {{ record.invoice_no ? record.invoice_no : null }}
            </td>
            <td
              :class="
                record.is_deleted ? 'tw-line-through tw-text-red-600' : ''
              "
              class="tw-text-right"
            >
              <p class="tw-mb-0 tw-font-extrabold tw-text-blue-700">
                {{ sumTotalAmount(record.detail) }}
              </p>
            </td>
            <td
              :class="
                record.is_deleted ? 'tw-line-through tw-text-red-600' : ''
              "
              class="tw-text-center"
            >
              {{ record.stock_in_date ? record.stock_in_date : null }}
            </td>
            <td
              :class="
                record.is_deleted ? 'tw-line-through tw-text-red-600' : ''
              "
              class="tw-text-center"
            >
              <span class="tw-text-green-500" v-if="record.is_confirm_stock">
                <a-icon type="check-circle" />
              </span>
              <span class="tw-text-red-500" v-else>
                <a-icon type="close-circle" class="tw-text-lg" />
              </span>
            </td>

            <td
              :class="
                record.is_deleted ? 'tw-line-through tw-text-red-600' : ''
              "
            >
              {{ record.confirmed_date }}
            </td>

            <td
              :class="
                record.is_deleted ? 'tw-line-through tw-text-red-600' : ''
              "
              class="tw-text-center"
            >
              {{ record.confirm_by_name }}
            </td>
            <td class="text-center tw-space-x-3" v-if="!record.is_deleted">
              <a
                v-if="!record.confirmed_by"
                href="#"
                v-tooltip="$t('edit')"
                class="text-primary"
                @click.prevent="edit(record)"
              >
                <i class="fas fa-edit"></i>
              </a>
              <a-popconfirm
                v-if="!record.confirmed_by && $whois.admin()"
                placement="leftTop"
                :title="$t('are_you_sure_to_delete')"
                :ok-text="$t('yes')"
                :cancel-text="$t('no')"
                @confirm="deleteRecord(record)"
              >
                <a href="#" :disabled="record._deleting">
                  <i
                    class="fas fa-circle-notch spin"
                    v-if="record._deleting"
                  ></i>
                  <i class="fa fa-trash text-red" v-else></i>
                </a>
              </a-popconfirm>
              <a-popconfirm
                placement="leftTop"
                :title="$t('are_you_sure_to_confirm')"
                :ok-text="$t('yes')"
                :cancel-text="$t('no')"
                @confirm="confirmStock(record)"
                v-if="!record.confirmed_by && ($whois.admin() || $whois.agency())"
              >
                <a href="#" :disabled="record._deleting">
                  <i
                    class="fas fa-circle-notch spin"
                    v-if="record._deleting"
                  ></i>
                  <i class="fas fa-check-square" v-else></i>
                </a>
              </a-popconfirm>
            </td>
          </template>
          <template v-slot:expand="{ record }">
            <table class="tw-w-full table">
              <thead>
                <tr>
                  <th class="tw-text-center">#</th>
                  <th>{{ $t('stockIn.barcode') }}</th>
                  <th>{{ $t('stockIn.productName') }}</th>
                  <th>{{ $t('stockIn.uom') }}</th>
                  <th class="tw-w-4 tw-text-center">{{ $t('stockIn.qty') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(d, index) in record.detail"
                  :key="index"
                >
                  <td class="tw-text-center tw-w-2">
                    {{ index + 1 }}
                  </td>
                  <td>{{ d.barcode }}</td>
                  <td>{{ d.product_name_en }}</td>
                  <td>{{ d.uom_name_en }}</td>
                  <td class="tw-text-center">{{ d.quantity }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </ts-table>
      </div>
      <div class="d-flex justify-content-end tw-p-4">
        <ts-pagination v-model="pagination" @navigate="fetchData">
        </ts-pagination>
      </div>
    </ts-panel>
    <a-modal :title="$t('pdf')" width="50%" v-model="show_pdf" :footer="null">
      <ts-preview-pdf v-model="waiting" :src="src" />
    </a-modal>
  </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { debounce, round, sumBy } from "lodash";
import { mapState } from "vuex";
export default {
  name: "stockIn-Index",
  data() {
    return {
      errors: new Errors(),
      loading: false,
      waiting: false,
      src: null,
      show_pdf: false,
    };
  },
  computed: {
    ...mapState("inventory/stockIn", ["resources", "pagination"]),
    search: {
      get() {
        return this.$store.state.inventory.stockIn.search;
      },
      set(newValue) {
        this.$store.commit("inventory/stockIn/SET_SEARCH", newValue);
        this.$store.commit("inventory/stockIn/RESET_CURRENT_PAGE");
      },
    },
    isConfirmedStock: {
      get() {
        return this.$store.state.inventory.stockIn.isConfirmedStock;
      },
      set(newValue) {
        this.$store.commit(
          "inventory/stockIn/SET_IS_CONFIRMED_STOCK",
          newValue
        );
      },
    },
    dateRange: {
      get() {
        return this.$store.state.inventory.stockIn.dateRange;
      },
      set(newValue) {
        this.$store.commit("inventory/stockIn/SET_DATE_RANGE", newValue);
      },
    },
    columns() {
      return [
        { name: this.$t("stockIn.stockInNumber") },
        { name: this.$t("stockIn.shop") },
        { name: this.$t("stockIn.invoiceNo") },
        { name: this.$t("stockIn.amount"), style: "text-align:right" },
        { name: this.$t("stockIn.stockInDate"), style: "text-align:center;" },
        {
          name: this.$t("stockIn.confirmStock"),
          style: "text-align:center;",
        },
        {
          name: this.$t("stockIn.confirmDate"),
          style: "text-align:center; ",
        },
        {
          name: this.$t("stockIn.confirmBy"),
          style: "text-align:center;",
        },
        {
          name: this.$t("actions"),
          style: "text-align:center;",
        },
      ];
    },
  },

  methods: {
    addNew() {
      this.$router.push({ name: "stock-in-create" });
    },
    fetchData(attributes) {
      this.loading = true;
      this.$store
        .dispatch("inventory/stockIn/fetch", { ...attributes })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$notify({ type: "error", text: error.message });
        });
    },
    edit(record) {
      this.$store.commit("inventory/stockIn/SET_EDIT_DATA", record);
      this.$router.push({
        name: "stock-in-edit",
        params: { id: record.stock_in_id },
      });
    },
    deleteRecord(record) {
      record._deleting = true;
      this.$store
        .dispatch("inventory/stockIn/destroy", record.stock_in_id)
        .then((response) => {
          this.$notify({ type: "success", text: response.message });
          this.fetchData();
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.$notify({ type: "error", text: error.message });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmStock(record) {
      record._deleting = true;
      this.$store
        .dispatch("inventory/stockIn/confirmStock",{
          stock_in_id: record.stock_in_id
        })
        .then((response) => {
          this.$notify({ type: "success", text: response.message });
          this.fetchData();
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.$notify({ type: "error", text: error.message });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    branchFilter(value) {
      this.$store.commit("inventory/stockIn/SET_BRANCH_ID", value);
      this.fetchData();
    },
    sumTotalAmount(detail = []) {
      detail.forEach((el) => {
        el.amount = el.quantity * el.cost;
      });
      return this.$currencyFormat(round(sumBy(detail, "amount"), 2));
    },
  },
  watch: {
    search: debounce(function () {
      this.fetchData();
    }, 500),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchData();
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("inventory/stockIn/RESET_STATE");
    next();
  },
};
</script>
